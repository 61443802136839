exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joinus-js": () => import("./../../../src/pages/joinus.js" /* webpackChunkName: "component---src-pages-joinus-js" */),
  "component---src-pages-origins-js": () => import("./../../../src/pages/origins.js" /* webpackChunkName: "component---src-pages-origins-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/event-template.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-project-location-template-js": () => import("./../../../src/templates/project-location-template.js" /* webpackChunkName: "component---src-templates-project-location-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/project-template.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

